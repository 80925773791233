import React, { useState, useCallback } from 'react'
import { Title } from '../text'
import Modal from '../modal'
import { Img } from './style'
import { IStatus } from '../../lib/statuses'

const Titles = {
  pending: '見積もり依頼を送信しました',
  payment: '見積もりを送信しました',
  delivery: '決済が完了しました',
  check: '納品しました',
  review: '評価を送信しました',
  done: '取引が完了しました',
  reject: 'この取引はキャンセルしました'
}

const images = {
  pending: 'https://storage.googleapis.com/writone-ja-admin/works/order.png',
  payment:
    'https://storage.googleapis.com/writone-ja-admin/works/new_message.png',
  delivery: 'https://storage.googleapis.com/writone-ja-admin/works/payment.png',
  check: 'https://storage.googleapis.com/writone-ja-admin/works/delivery.png',
  review:
    'https://storage.googleapis.com/writone-ja-admin/works/new_message.png',
  done: 'https://storage.googleapis.com/writone-ja-admin/works/done.png',
  reject: 'https://storage.googleapis.com/writone-ja-admin/works/cancel.png'
}
const PopupContext = React.createContext({
  setPopup: (state: IStatus) => {}
})

const Popup = ({ children }: { children: React.ReactNode }) => {
  const [status, setStatus] = useState<IStatus | null>(null)

  const onhandle = useCallback((status: IStatus) => {
    setStatus(status)
    const timer = setTimeout(() => {
      setStatus(null)
    }, 3000)
    return () => clearTimeout(timer)
  }, [])

  return (
    <PopupContext.Provider
      value={{
        setPopup: (status: IStatus) => onhandle(status)
      }}
    >
      {children}
      {status && (
        <Modal>
          <Img src={images[status]} />
          <Title mb={10} align={'center'}>
            {Titles[status]}
          </Title>
        </Modal>
      )}
    </PopupContext.Provider>
  )
}
export default Popup
export const usePopup = () => React.useContext(PopupContext)
