import React, { lazy, Suspense } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import PlayerProvider from './hooks/usePlayer'
import AuthProvider from './contexts/Auth'
import Toast from './components/toast'
import LoadingProvider from './contexts/loading'
import Popup from './components/popup'
import GAListener from './lib/withTracker'
const Desctop = lazy(() => import('./pages/desctop'))
const Home = lazy(() => import('./pages/home'))
const Todo = lazy(() => import('./pages/todo'))
const Notify = lazy(() => import('./pages/notify'))
const Service = lazy(() => import('./pages/service'))
const OrderForm = lazy(() => import('./pages/orderForm'))
const Order = lazy(() => import('./pages/order'))
const OrderAdmin = lazy(() => import('./pages/order/admin'))
const EditService = lazy(() => import('./pages/editService'))
const Terms = lazy(() => import('./pages/terms'))
const PrivacyPolicy = lazy(() => import('./pages/privacyPolicy'))
const Specified = lazy(() => import('./pages/specified'))
const Rules = lazy(() => import('./pages/rules'))
const Login = lazy(() => import('./pages/login'))
const ForgotPass = lazy(() => import('./pages/forgotPass'))
const GuidePayment = lazy(() => import('./pages/guidePayment'))
const GuideBuy = lazy(() => import('./pages/guideBuy'))
const Setting = lazy(() => import('./pages/setting'))
const Estimate = lazy(() => import('./pages/estimate'))
const ChangeEmail = lazy(() => import('./pages/changeEmail'))
const ChangePassword = lazy(() => import('./pages/changePassword'))
const ChangeBank = lazy(() => import('./pages/changeBank'))
const CreditCard = lazy(() => import('./pages/creditCard'))
const Review = lazy(() => import('./pages/review'))

const App: React.FC = () => {
  return (
    <Router>
      <AuthProvider>
        <GAListener>
          <LoadingProvider>
            <Toast>
              <Popup>
                <Suspense fallback={''}>
                  <Switch>
                    <Route
                      exact
                      path={['/login', '/signup']}
                      component={Login}
                    />
                    <Route exact path={'/forgot'} component={ForgotPass} />
                    <Desctop>
                      <PlayerProvider>
                        <Switch>
                          <Route exact path={'/'} component={Home} />
                          <Route exact path={'/todo'} component={Todo} />
                          <Route exact path={'/notify'} component={Notify} />
                          <Route exact path={'/setting'} component={Setting} />
                          <Route
                            exact
                            path="/setting/email"
                            component={ChangeEmail}
                          />
                          <Route
                            exact
                            path="/setting/password"
                            component={ChangePassword}
                          />
                          <Route
                            exact
                            path="/setting/bank"
                            component={ChangeBank}
                          />
                          <Route
                            exact
                            path="/setting/creditCard"
                            component={CreditCard}
                          />
                          <Route
                            exact
                            path={'/service/edit'}
                            component={EditService}
                          />
                          <Route
                            exact
                            path={'/service/:targetId'}
                            component={Service}
                          />
                          <Route
                            exact
                            path={'/form/:targetId'}
                            component={OrderForm}
                          />
                          <Route
                            exact
                            path={'/order/:orderId'}
                            component={Order}
                          />
                          <Route
                            exact
                            path={[
                              '/contact/:orderId',
                              '/contact/:orderId/:uid'
                            ]}
                            component={OrderAdmin}
                          />
                          <Route
                            exact
                            path={'/estimate/:orderId'}
                            component={Estimate}
                          />
                          <Route
                            exact
                            path={'/review/:orderId'}
                            component={Review}
                          />

                          {/* サイト情報 */}
                          <Route exact path={'/terms'} component={Terms} />
                          <Route
                            exact
                            path={'/privacyPolicy'}
                            component={PrivacyPolicy}
                          />
                          <Route
                            exact
                            path={'/specified'}
                            component={Specified}
                          />

                          {/* ご利用ガイド */}
                          <Route
                            exact
                            path={'/guidePayment'}
                            component={GuidePayment}
                          />
                          <Route
                            exact
                            path={'/guideBuy'}
                            component={GuideBuy}
                          />
                          <Route exact path={'/rules'} component={Rules} />
                        </Switch>
                      </PlayerProvider>
                    </Desctop>
                  </Switch>
                </Suspense>
              </Popup>
            </Toast>
          </LoadingProvider>
        </GAListener>
      </AuthProvider>
    </Router>
  )
}

export default App
