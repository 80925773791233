import React from 'react'
import { Wrapper, Image } from './style'

interface IProps {
  color?: string
}
const Loading = ({ color }: IProps) => {
  return (
    <Wrapper color={color}>
      <Image
        src={'https://storage.googleapis.com/writone-ja-admin/loading.gif'}
      />
    </Wrapper>
  )
}
export default Loading
