import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/messaging'
import 'firebase/functions'
const config = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FB_DATABASE_URL,
  projectId: process.env.REACT_APP_FB_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FB_APP_ID
}
firebase.initializeApp(config)
const functions = firebase.app().functions('asia-northeast1')
// if (process.env.NODE_ENV !== 'production') {
//   functions.useFunctionsEmulator('http://localhost:5000')
// }
const db = firebase.firestore()
db.enablePersistence({ synchronizeTabs: true }).catch(e => {
  console.log('=====error firestore persistence')
  console.error(e)
})
let messaging: firebase.messaging.Messaging
if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging()
  messaging.usePublicVapidKey(process.env.REACT_APP_CLOUD_MESSAGE_KEY!)
}
export { functions, db, messaging }

export const audiosRef = db.collection('versions/v1/audios')
export const booksRef = db.collection('versions/v1/books')
export const usersRef = db.collection('versions/v1/users')
export const adminNotificationsRef = db.collection(
  'versions/v1/adminNotifications'
)
export const creatorsRef = db.collection('versions/v1/creators')
export const ordersRef = db.collection('versions/v1/orders')
export const transfersRef = db.collection('versions/v1/transfers')

export default firebase
