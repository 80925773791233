import { useEffect } from 'react'
import ReactGA from 'react-ga'
import { withRouter, RouteComponentProps } from 'react-router'
ReactGA.initialize(process.env.REACT_APP_GA_ID!)

interface Props extends RouteComponentProps {
  children: JSX.Element
}
const GAListener = ({ children, location }: Props) => {
  useEffect(() => {
    ReactGA.set({ page: location.pathname })
    if (location.search) {
      return ReactGA.modalview(location.pathname + location.search)
    }
    ReactGA.pageview(location.pathname)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return children
}

export default withRouter(GAListener)
