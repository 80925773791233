import styled from 'styled-components'
import { ColCenter } from '../row'

export const Wrapper = styled(ColCenter)`
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
  z-index: 200;
`

export const Container = styled(ColCenter)`
  position: relative;
  width: 250px;
  padding: 20px;
  background: #fff;
  border-radius: 20px;
  @media screen and (min-width: 768px) {
    width: 400px;
  }
`
