import styled from 'styled-components'

interface TextProps {
  size?: number
  weight?: number
  color?: string
  align?: string
  gridArea?: string
  ellipsis?: number
  mt?: number //margin-top
  mr?: number //margin-right
  mb?: number //margin-bottom
  ml?: number //margin-left
  padding?: string
  overflow?: string
  active?: boolean
}

const getEllipsis = (ellipsis?: number) => {
  if (ellipsis === 1) {
    return `
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    `
  } else if (ellipsis! > 1) {
    return `
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: ${ellipsis};
    `
  }
}

export const P1 = styled.p`
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.7;
  color: ${(props: TextProps) => props.color};
  text-align: ${(props: TextProps) => props.align};
  grid-area: ${(props: TextProps) => props.gridArea};
  margin-top: ${(props: TextProps) => props.mt}px;
  margin-right: ${(props: TextProps) => props.mr}px;
  margin-bottom: ${(props: TextProps) => props.mb}px;
  margin-left: ${(props: TextProps) => props.ml}px;
  overflow: ${(props: TextProps) => props.overflow};
  ${(props: TextProps) => getEllipsis(props.ellipsis)}
  word-break: break-all;
`
P1.defaultProps = {
  color: '#3B4B6A',
  align: 'left',
  gridArea: 'text',
  mt: 0,
  mr: 0,
  mb: 0,
  ml: 0,
  overflow: 'visible'
}

export const P2 = styled(P1)`
  font-size: 1.4rem;
`

export const P3 = styled(P1)`
  font-size: 1.2rem;
`

export const P4 = styled(P1)`
  font-size: 1rem;
`

export const H1 = styled.h1`
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.7;
  color: ${(props: TextProps) => props.color};
  text-align: ${(props: TextProps) => props.align};
  grid-area: ${(props: TextProps) => props.gridArea};
  margin-top: ${(props: TextProps) => props.mt}px;
  margin-right: ${(props: TextProps) => props.mr}px;
  margin-bottom: ${(props: TextProps) => props.mb}px;
  margin-left: ${(props: TextProps) => props.ml}px;
  ${(props: TextProps) => getEllipsis(props.ellipsis)}
  word-break: break-all;
`

const hDefaultProps = {
  color: '#3B4B6A',
  align: 'left',
  gridArea: 'text',
  mt: 0,
  mr: 0,
  mb: 0,
  ml: 0
}
H1.defaultProps = hDefaultProps

export const Title = styled(H1)`
  font-size: 2rem;
`

export const H2 = styled.h2`
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.7;
  color: ${(props: TextProps) => props.color};
  text-align: ${(props: TextProps) => props.align};
  grid-area: ${(props: TextProps) => props.gridArea};
  margin-top: ${(props: TextProps) => props.mt}px;
  margin-right: ${(props: TextProps) => props.mr}px;
  margin-bottom: ${(props: TextProps) => props.mb}px;
  margin-left: ${(props: TextProps) => props.ml}px;
  ${(props: TextProps) => getEllipsis(props.ellipsis)}
  word-break: break-all;
`
H2.defaultProps = {
  ...hDefaultProps,
  active: true
}

export const H3 = styled.h3`
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.7;
  color: ${(props: TextProps) => props.color};
  text-align: ${(props: TextProps) => props.align};
  grid-area: ${(props: TextProps) => props.gridArea};
  margin-top: ${(props: TextProps) => props.mt}px;
  margin-right: ${(props: TextProps) => props.mr}px;
  margin-bottom: ${(props: TextProps) => props.mb}px;
  margin-left: ${(props: TextProps) => props.ml}px;
  ${(props: TextProps) => getEllipsis(props.ellipsis)}
  word-break: break-all;
`
H3.defaultProps = hDefaultProps
export const H4 = styled.h4`
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.7;
  color: ${(props: TextProps) => props.color};
  text-align: ${(props: TextProps) => props.align};
  grid-area: ${(props: TextProps) => props.gridArea};
  margin-top: ${(props: TextProps) => props.mt}px;
  margin-right: ${(props: TextProps) => props.mr}px;
  margin-bottom: ${(props: TextProps) => props.mb}px;
  margin-left: ${(props: TextProps) => props.ml}px;
  ${(props: TextProps) => getEllipsis(props.ellipsis)}
  word-break: break-all;
`
H4.defaultProps = hDefaultProps

export const Span = styled.span`
  font-size: ${(props: TextProps) => props.size}rem;
  font-weight: ${(props: TextProps) => props.weight};
  line-height: 1.7;
  color: ${(props: TextProps) => props.color};
  text-align: ${(props: TextProps) => props.align};
  grid-area: ${(props: TextProps) => props.gridArea};
  margin-top: ${(props: TextProps) => props.mt}px;
  margin-right: ${(props: TextProps) => props.mr}px;
  margin-bottom: ${(props: TextProps) => props.mb}px;
  margin-left: ${(props: TextProps) => props.ml}px;
  ${(props: TextProps) => getEllipsis(props.ellipsis)}
  word-break: break-all;
`
Span.defaultProps = {
  size: 1.4,
  weight: 300,
  color: '#3B4B6A',
  align: 'left',
  gridArea: 'span',
  mt: 0,
  mr: 0,
  mb: 0,
  ml: 0
}
