import styled from 'styled-components'

interface RowProps {
  width?: string
  justify?: string
  align?: string
  mt?: number
  mr?: number
  mb?: number
  ml?: number
  active?: boolean
}

export const Row = styled.div`
  display: flex;
  justify-content: ${(props: RowProps) => props.justify};
  align-items: ${(props: RowProps) => props.align};
  width: ${(props: RowProps) => props.width};
  margin-top: ${(props: RowProps) => props.mt}px;
  margin-right: ${(props: RowProps) => props.mr}px;
  margin-bottom: ${(props: RowProps) => props.mb}px;
  margin-left: ${(props: RowProps) => props.ml}px;
  flex-shrink: 0;
  opacity: ${(props: RowProps) => (props.active ? 1 : 0.4)};
`
Row.defaultProps = {
  width: '100%',
  align: 'center',
  mt: 0,
  mb: 0,
  mr: 0,
  ml: 0,
  active: true
}

export const RowCenter = styled(Row)`
  justify-content: center;
`

export const RowBt = styled(Row)`
  justify-content: space-between;
`

export const Col = styled(Row)`
  flex-direction: column;
  align-items: flex-start;
`

export const ColCenter = styled(Col)`
  align-items: center;
`
