import styled from 'styled-components'
import { Col } from '../row'

export const Wrapper = styled(Col)`
  width: 100%;
  height: 100%;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
`
export const Image = styled.img`
  width: 100px;
  padding: 20px;
  background: #fff;
  border-radius: 20px;
`
