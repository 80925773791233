import React, { ReactNode } from 'react'
import { Wrapper, Container } from './style'

const Modal = ({ children }: { children: ReactNode }) => {
  return (
    <Wrapper>
      <Container>{children}</Container>
    </Wrapper>
  )
}

export default Modal
