import React, {
  ReactNode,
  useState,
  useEffect,
  createContext,
  useContext
} from 'react'
import firebase from '../firebase'

interface User {
  user: firebase.User | null
  reAuth?: () => void
}
const userContext = createContext<User>({ user: null })

export const useSession = () => {
  const { user, reAuth } = useContext(userContext)
  return { user, reAuth }
}

export const useAuth = () => {
  const [state, setState] = useState(() => {
    const user = firebase.auth().currentUser
    return {
      initializing: !user,
      user
    }
  })

  const reAuth = () => {
    const user = firebase.auth().currentUser
    setState({
      initializing: false,
      user
    })
  }
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      if (!user) {
        return firebase.auth().signInAnonymously()
      }
      setState({ initializing: false, user })
    })
    return () => unsubscribe()
  }, [])

  return { ...state, reAuth }
}

interface IProps {
  children: ReactNode
}
const AuthProvider = ({ children }: IProps) => {
  const { user, reAuth } = useAuth()

  return (
    <userContext.Provider value={{ user, reAuth }}>
      {children}
    </userContext.Provider>
  )
}
export default AuthProvider
