import React, {
  ReactNode,
  useState,
  useEffect,
  createContext,
  useContext
} from 'react'

interface IState {
  audioId?: string
  src: string
  hlsURL: string
  playingId?: string
}
const el = document.getElementById('audio') as HTMLAudioElement
const hls = document.getElementById('hls') as HTMLSourceElement
const source = document.getElementById('default') as HTMLSourceElement
const usePlayInfo = () => {
  const [data, setPlayData] = useState<IState>({
    audioId: '',
    src: '',
    hlsURL: '',
    playingId: ''
  })

  useEffect(() => {
    if (data.hlsURL) {
      hls.src = data.hlsURL
    }
    if (data.src) {
      source.src = data.src
      el.load()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.src])

  return {
    audioId: data.audioId,
    playingId: data.playingId,
    setPlayData
  }
}

const usePlayer = () => {
  const [state, setState] = useState<HTMLMediaState>(initialState)
  const el = document.getElementById('audio') as HTMLAudioElement
  useEffect(() => {
    el.addEventListener('loadeddata', onLoaded)
    el.addEventListener('play', onPlay)
    el.addEventListener('pause', onPause)

    return () => {
      el.removeEventListener('loadeddata', onLoaded)
      el.removeEventListener('play', onPlay)
      el.removeEventListener('pause', onPause)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onPlay = () =>
    setState({
      isPlaying: true
    })
  const onPause = () =>
    setState({
      isPlaying: false
    })

  const onLoaded = () => {
    el.play().catch(e => console.error(e))
    setState({
      isPlaying: !el.paused
    })
  }

  let lockPlay: boolean = false

  const controls = {
    play: () => {
      if (!el) return undefined

      if (!lockPlay) {
        const promise = el.play()
        const isPromise = typeof promise === 'object'

        if (isPromise) {
          lockPlay = true
          const resetLock = () => {
            lockPlay = false
          }
          promise.then(resetLock, resetLock)
        }

        return promise
      }
      return undefined
    },
    pause: () => {
      if (el && !lockPlay) {
        return el.pause()
      }
    },
    seek: (time: number) => {
      if (!el) return
      time = Math.min(el.duration, Math.max(0, el.currentTime + time))
      el.currentTime = time
    },

    rateChange: (rate: number) => {
      el.playbackRate = rate
    }
  }

  return {
    state,
    controls
  }
}

interface HTMLMediaState {
  isPlaying: boolean
}

interface HTMLMediaControls {
  play: () => Promise<void> | void
  pause: () => void
  seek: (time: number) => void
  rateChange: (rate: number) => void
}
interface IContext {
  state: HTMLMediaState
  controls?: HTMLMediaControls
  audioId?: string
  playingId?: string
  setPlayData?: React.Dispatch<
    React.SetStateAction<{
      src: string
      hlsURL: string
      audioId?: string
      playingId?: string
    }>
  >
}
const initialState = {
  isPlaying: false
}

const audioContext = createContext<IContext>({
  state: initialState,
  audioId: '',
  playingId: ''
})

export const usePlayerSession = () => {
  const { state, controls, audioId, playingId, setPlayData } = useContext(
    audioContext
  )
  return { state, controls, audioId, playingId, setPlayData }
}

interface IProps {
  children: ReactNode
}
const PlayerProvider = ({ children }: IProps) => {
  const { state, controls } = usePlayer()
  const { playingId, setPlayData } = usePlayInfo()
  return (
    <audioContext.Provider value={{ state, controls, playingId, setPlayData }}>
      {children}
    </audioContext.Provider>
  )
}
export default PlayerProvider
