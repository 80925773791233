import React, { useState, useCallback } from 'react'
import { Title, P2 } from '../text'
import './style.css'

type IState = {
  title: string
  content: string
}
const ToastContext = React.createContext({
  toggle: () => {},
  setTitle: (state: IState) => {}
})

const Toast = ({ children }: { children: React.ReactNode }) => {
  const [active, toggle] = useState(false)
  const [state, setState] = useState({
    title: '',
    content: ''
  })

  const onClick = useCallback(() => {
    toggle(true)
    const timer = setTimeout(() => {
      toggle(false)
    }, 4800)
    return () => clearTimeout(timer)
  }, [])

  return (
    <ToastContext.Provider
      value={{
        toggle: onClick,
        setTitle: (state: IState) => setState(state)
      }}
    >
      {children}
      <div id="snackbar" className={active ? 'show' : ''}>
        <Title mb={10} color="#fff">
          {state.title}
        </Title>
        <P2 color="#fff">{state.content}</P2>
      </div>
    </ToastContext.Provider>
  )
}
export default Toast
export const useToast = () => React.useContext(ToastContext)
