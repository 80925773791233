import React, { ReactNode } from 'react'
import Loading from '../components/Loading'

interface IState {
  loading: boolean
}

const LoadingContext = React.createContext({
  setLoading: ({ loading }: IState) => {}
})

export default ({ children }: { children: ReactNode }) => {
  const [state, update] = React.useState<IState>({
    loading: false
  })

  const setLoading = (data: IState) => {
    update(state => {
      return { ...state, ...data }
    })
  }
  const { loading } = state
  return (
    <LoadingContext.Provider value={{ setLoading }}>
      {children}
      {loading && <Loading />}
    </LoadingContext.Provider>
  )
}

export const useLoading = () => React.useContext(LoadingContext)
